import styled from "styled-components";
import { device } from "../components/Helpers";

export const EdetailExpertGuidanceStyles = styled.main`
position: relative;
    margin: 12.5rem auto 0;

section{
  margin-top:0;
}
  #ntm-treatment-guidelines {
    margin-bottom: 2.875rem;
    @media ${device.mobile} {
      margin-bottom: 0;
    }
    & > h1 {
      line-height: 1.75rem;
      p {
        font-size: 1rem;
        line-height: 1rem;
        color: ${(props) => props.theme.colors.brand_orange_00};
        font-weight: 600;
        margin-bottom: 0.9375rem;
        text-transform: uppercase;
        @media ${device.mobile} {
          text-transform: uppercase;
          line-height: normal !important;
        }
      }
    }
    & > .callout-container {
      align-items: stretch;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-evenly;
      margin-top: 5.5rem;
      @media ${device.mobile} {
        flex-direction: column;
      }

      [data-component='callout']{
      h2 {
        font-weight:bold;
      }
      }
      .purp.calendar {
		h2 {
		  padding: 0 2rem;
		}
        @media ${device.mobile} {
          width: 100%;
          margin-bottom: 0;
          margin-top: 1rem;
        }
      }

      & > div {
        margin: 0 0.75rem;
        padding: 4rem 1.5rem 3rem 1.5rem;
        position: relative;
        width: 33%;
		h2 {
		  margin-bottom: 1.25rem;
		  padding: 0 5rem;
		  text-align: center;
		  font-weight: 400;
		}

        @media ${device.mobile} {
          width: 100%;
          margin-bottom: 4.5rem;
          margin-left: 0;
        }

        &.pills::after {
          background-image: url("../images/icon-regimen.svg");
          padding: 2.25rem;
          margin-left: 36%;
          @media ${device.mobile} {
            padding: 2.5rem;
          }
        }

        &.nebulizer::after {
          background-image: url("../images/icon-nebulizer.svg");
          padding: 2.25rem;
          margin-left: 36%;
          @media ${device.mobile} {
            padding: 2.5rem;
          }
        }

        &.calendar::after {
          background-image: url("../images/icon-calendar.svg");
          padding: 2.25rem;
          background-color: transparent;
          border: none;
          margin-top: -2.5rem;
        }

        &::after {
          content: "";
          background-color: ${(props) => props.theme.colors.white};
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          border: 1rem solid ${(props) => props.theme.colors.white};
          left: 0;
          margin-left: 40%;
          margin-top: -3.35rem;
          position: absolute;
          top: 0;
        }

        &:nth-of-type(1) {
          margin-left: 0;
        }

        &:nth-of-type(2) > h2 {
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  #video-library {
    margin: 2.5rem auto;
    @media ${device.mobile} {
      margin-top: 5rem;
    }
    h1 {
      @media ${device.mobile} {
        margin-bottom: 1.6667rem;
      }
    }
  }
  .video-title > h2 {
    @media ${device.mobile} {
      font-size: 16px;
      line-height: normal;
    }
  }

  .definitions {
    margin-top: 0;
    @media ${device.mobile} {
      margin-top: 0;
    }
  }

  .definitions-section {
    @media ${device.mobile} {
      margin-top: 0;
    }
  }

  .footnote{
    margin-bottom:1.313rem;
    @media ${device.mobile} {
      margin-top: 2.5rem;
    }
  }
  .transcript{
    margin-top:1.875rem;
  }
`;
